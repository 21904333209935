import {
  AddToCartData,
  CartQuoteData,
  OrderData,
  ProductData,
  SearchData,
} from './types.js'

export type FacebookPixelsEvent =
  | 'PageView'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'InitiateCheckout'
  | 'Purchase'
  | 'Search'
  | 'ViewContent'
  | 'revoke'
  | 'grant'

type EventProp = string | string[] | {} | undefined
type FbPixelData = Record<string, EventProp>

interface FbAddToCartData extends FbPixelData {
  content_ids: string[] // product sku
  content_type: 'product' //
  content_name: string // product name
  contents?: Record<string, {}> // unknown
  currency: string // currency
  value: string // product price
}

interface FbViewProductData extends FbPixelData {
  content_ids: string[] // product sku
  content_category?: string // product category
  content_type: 'product' //
  content_name: string // product name
  currency: string // currency
  value: string // product price
}

interface FbViewListingData extends FbPixelData {
  content_ids: string[] // product sku
  content_category?: string // product category
  content_type: 'product_group' //
  currency: string // currency
}

interface FbPurchaseData extends FbPixelData {
  content_ids: string[] // products skus
  currency: string // currency of the order
  num_items: string // number of products
  value: string // order value
}

interface FbInitiateCheckoutData extends FbPixelData {
  content_category?: string // products categories
  content_ids: string[] // products skus
  currency: string // currency of the order
  num_items: string // number of products
  value: string // order value
}

interface FbAddToWishlistData extends FbPixelData {
  content_category?: string
  content_name: string
  content_ids: string[]
  currency: string
  value: string
}

interface FbSearchData extends FbPixelData {
  search_string: string
}

declare global {
  interface Window {
    customFbq: (
      t: 'track',
      e: FacebookPixelsEvent,
      v?: FbPixelData
    ) => void
  }
}

export function pixelTrack(
  event: FacebookPixelsEvent,
  data?: FbPixelData
): void {
  if (window.customFbq) {
    window.customFbq('track', event, data)
  } else {
    console.log('Facebook Pixel not initialized');
  }
}

export function pixelPageView(): void {
  pixelTrack('PageView')
}

export function pixelViewProduct(data: ProductData): void {
  const _data: FbViewProductData = {
    content_ids: [data.sku],
    content_type: 'product',
    content_name: data.name,
    currency: data.currency,
    value: data.price.toString(),
  }

  if (data.category) {
    _data.content_category = data.category
  }

  pixelTrack('ViewContent', _data)
}

export function pixelViewList(category: string, data: ProductData[]): void {
  const _data: FbViewListingData = {
    content_ids: data.map((p) => p.sku),
    content_category: category,
    content_type: 'product_group',
    currency: data[0]?.currency ?? "BGN",
  }

  pixelTrack('ViewContent', _data)
}

export function pixelAddToCart(data: AddToCartData): void {
  const _data: FbAddToCartData = {
    content_ids: [data.sku],
    content_type: 'product',
    content_name: data.name,
    currency: 'BGN',
    value: data.price.toString(),
  }
  pixelTrack('AddToCart', _data)
}

export function pixelOrderComplete(data: OrderData): void {
  const _data: FbPurchaseData = {
    content_ids: data.products.map((p) => p.sku),
    currency: data.currency,
    num_items: data.products.length.toString(),
    value: data.total.toString(),
  }
  pixelTrack('Purchase', _data)
}

export function pixelCheckoutStarted(data: CartQuoteData): void {
  const _data: FbInitiateCheckoutData = {
    content_ids: data.products.map((p) => p.sku),
    currency: data.currency,
    num_items: data.products.length.toString(),
    value: data.total.toString(),
  }
  pixelTrack('InitiateCheckout', _data)
}

export function pixelAddToWishlist(data: ProductData): void {
  const _data: FbAddToWishlistData = {
    content_name: data.name,
    content_ids: [data.sku],
    currency: data.currency,
    value: data.price.toString(),
  }
  pixelTrack('AddToWishlist', _data)
}

export function pixelSearch(data: SearchData): void {
  const _data: FbSearchData = {
    search_string: data.search_term,
  }
  pixelTrack('Search', _data)
}
