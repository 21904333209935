import {
  AddToCartData,
  CartQuoteData,
  EcommerceEventData,
  ListViewData,
  OrderData,
  ProductData,
  SearchData,
} from './types.ts'

declare global {
  interface Window {
    gtag: (e: string, v: string, v2?: any) => void
    dataLayer: any[]
  }
}

type GtagEvent =
  | 'add_to_wishlist'
  | 'search'
  | 'view_item_list'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'purchase'

interface GtagItem {
  item_id: string
  item_name: string
  item_brand?: string
  item_category?: string
  price?: number
  quantity?: number
}

interface GTagCart {
  currency: string
  value: number
  items: GtagItem[]
}

// purchase
interface GTagConversion {
  currency: string
  transaction_id: string
  value: number
  revenue: number
  shipping?: number
  coupon?: string
  items: GtagItem[]
}

interface GTagSearch {
  search_term: string
}

interface GTagViewItemsList {
  item_list_id?: string
  item_list_name?: string
  items: GtagItem[]
}

interface GTagBeginCheckout extends GTagCart {}

interface GTagViewItem {
  currency: string
  value: number
  items: GtagItem[]
}

type GtagEventData =
  | GTagCart
  | GTagBeginCheckout
  | GTagViewItemsList
  | GTagViewItem
  | GTagConversion
  | GTagSearch

function _convertProductDataToGtagItem(
  _d: ProductData | AddToCartData
): GtagItem {
  let qty = 1

  if ('quantity' in _d && _d.quantity) {
    qty = _d.quantity
  }

  return {
    item_id: _d.sku,
    item_name: _d.name,
    item_brand: _d.brand,
    item_category: _d.category || '',
    price: _d.price,
    quantity: qty,
  }
}

export function gtagTrack(event: GtagEvent, data: EcommerceEventData): void {
  let eventData = {}

  if (event == 'add_to_cart') {
    eventData = getAddToCartEventData(data as AddToCartData)
  } else if (event == 'remove_from_cart') {
    eventData = getRemoveFromCartEventData(data as ProductData)
  } else if (event == 'purchase') {
    eventData = getPurchaseEventData(data as OrderData)
  } else if (event == 'search') {
    eventData = getSearchEventData(data as SearchData)
  } else if (event == 'begin_checkout') {
    eventData = getBeginCheckoutEventData(data as CartQuoteData)
  } else if (event == 'add_to_wishlist') {
    eventData = getRemoveFromCartEventData(data as ProductData)
  } else if (event == 'view_item_list') {
    eventData = getViewItemListEventData(data as ListViewData)
  } else if (event == 'view_item') {
    eventData = getViewItemEventData(data as ProductData)
  } else {
    console.log(event + ': event not supported')
    return
  }

  if (window.gtag) {
    window.gtag('event', event, eventData)
  } else {
    console.error('gtag not initialized')
  }
}

function getViewItemEventData(p: ProductData): GTagViewItem {
  return {
    currency: p.currency,
    value: p.price,
    items: [_convertProductDataToGtagItem(p)],
  }
}

function getViewItemListEventData(data1: ListViewData): GTagViewItemsList {
  return {
    item_list_name: data1.category || '',
    items: data1.products.map(_convertProductDataToGtagItem),
  }
}

function getAddToCartEventData(data: AddToCartData): GTagCart {
  const item = _convertProductDataToGtagItem(data)
  item.quantity = data.quantity

  return {
    currency: data.currency,
    value: data.value,
    items: [item],
  }
}

function getRemoveFromCartEventData(data: ProductData): GTagCart {
  return {
    currency: data.currency,
    value: data.price,
    items: [_convertProductDataToGtagItem(data)],
  }
}

function getPurchaseEventData(data: OrderData): GTagConversion {
  return {
    currency: data.currency,
    transaction_id: data.order_id,
    value: data.total,
    revenue: data.total,
    shipping: data.shipping,
    items: data.products.map(_convertProductDataToGtagItem),
  }
}

function getSearchEventData(data: SearchData): GTagSearch {
  return {
    search_term: data.search_term,
  }
}

function getBeginCheckoutEventData(data: CartQuoteData): GTagBeginCheckout {
  return {
    currency: data.currency,
    value: data.total,
    items: data.products.map(_convertProductDataToGtagItem),
  }
}

function getAddToWishlistEventData(data: CartQuoteData): GTagBeginCheckout {
  return {
    currency: data.currency,
    value: data.total,
    items: data.products.map(_convertProductDataToGtagItem),
  }
}
