import {gtagTrack} from './gtag.ts'
import {
  pixelAddToCart,
  pixelAddToWishlist,
  pixelCheckoutStarted,
  pixelOrderComplete,
  pixelSearch,
  pixelTrack, pixelViewList, pixelViewProduct
} from "./facebook.js"
import {
  AddToCartData,
  CartItem,
  CartQuoteData,
  EcommerceEvent,
  EcommerceEventData,
  ListViewData,
  Order,
  OrderData,
  ProductData,
  SearchData
} from "./types.js";

function ProductView(p: ProductData) {
  appEcomerceTrack('Product Viewed', p)
}

function ProductListView(p: ProductData[], category?: string) {
  if (!p || p.length < 1) {
    return
  }

  appEcomerceTrack('Product List Viewed', {
    category: category,
    products: p.map((_p) => {
      _p.category = category
      return _p
    }),
  })
}

function WishlistAdd(p: ProductData) {
  appEcomerceTrack('Product Added to Wishlist', p)
}

function ProductAddToCart(
  product: ProductData,
  price?: number,
  qty?: number
) {
  const data: AddToCartData = {
    quantity: qty || 1,
    value: price || product.price || 0,
    ...product,
  }

  appEcomerceTrack('Product Added', data)
}

function ProductSearch(s: string) {
  appEcomerceTrack('Products Searched', {
    search_term: s,
  })
}

function RemoveFromCart(cartItem: ProductData) {
  const _date: ProductData = {
    currency: 'BGN',
    name: cartItem.name,
    price: cartItem.price,
    sku: cartItem.sku,
  }
  appEcomerceTrack('Product Removed', _date)
}

function cartItemToTrackingProduct(item: CartItem): AddToCartData {
  const product = item.product
  return {
    ...product,
    currency: 'BGN',
    quantity: item.quantity || 1,
    value: item.row_total,
  }
}

function CheckoutInit(items: CartItem[], grandTotal: number) {
  let products: AddToCartData[] = []
  for (const item of items) {
    if (item?.product) {
      products.push(cartItemToTrackingProduct(item))
    }
  }

  appEcomerceTrack('Checkout Started', {
    currency: 'BGN',
    total: grandTotal,
    products: products,
  } as CartQuoteData)
}

function ViewedStep(s: number) {
  appEcomerceTrack('Checkout Step Viewed', {
    step: s,
  })
}

function OrderComplete(
  order: Order
) {
  appEcomerceTrack('Order Completed', {
    order_id: order.increment_id,
    total: order.totals.grand_total,
    shipping: order.totals.shipping,
    currency: (order.currency as string) ?? 'BGN',
    products: order.items.map((item) => (cartItemToTrackingProduct(item))),
  })
}

function appEcomerceTrack(
  event: EcommerceEvent,
  data: EcommerceEventData
): void {
  try {
    if (event === 'Order Completed') {
      gtagTrack('purchase', data as OrderData)
      pixelOrderComplete(data as OrderData)
    } else if (event === 'Checkout Started') {
      gtagTrack('begin_checkout', data as CartQuoteData)
      pixelCheckoutStarted(data as CartQuoteData)
    } else if (event === 'Product Added') {
      gtagTrack('add_to_cart', data as AddToCartData)
      pixelAddToCart(data as AddToCartData)
    } else if (event === 'Product Added to Wishlist') {
      gtagTrack('add_to_wishlist', data as ProductData)
      pixelAddToWishlist(data as ProductData)
    } else if (event === 'Product Removed') {
      gtagTrack('remove_from_cart', data as ProductData)
    } else if (event === 'Product List Viewed') {
      gtagTrack('view_item_list', data as ListViewData)
      pixelViewList(
        (data as ListViewData).category ?? "",
        (data as ListViewData).products,
      )
    } else if (event === 'Product Viewed') {
      gtagTrack('view_item', data as ProductData)
      pixelViewProduct(data as ProductData)
    } else if (event === 'Products Searched') {
      gtagTrack('search', data as SearchData)
      pixelSearch(data as SearchData)
    }
  } catch (e) {
    console.error("Event:Error", event, e)
  }
}

export default {
  ProductView,
  ProductListView,
  WishlistAdd,
  ProductAddToCart,
  ProductSearch,
  RemoveFromCart,
  CheckoutInit,
  ViewedStep,
  OrderComplete,
}
